import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextArea = makeShortcode("TextArea");
const TextColumn = makeShortcode("TextColumn");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextArea mdxType="TextArea">
      <TextColumn mdxType="TextColumn">
        <h1>{`Page Not Found`}</h1>
        <center>
          <p>{`Click `}<a parentName="p" {...{
              "href": "/"
            }}>{`here`}</a>{` to return home.`}</p>
        </center>
      </TextColumn>
    </TextArea>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      